.announcement-content a {
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f5fbd;
}

.announcement-content a:hover {
  text-decoration: underline;
}

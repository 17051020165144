body {
  margin: 0;
  overflow: overlay;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-ms-reveal {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background: #616161;
  border-radius: 3px;
}

/* Firefox-specific styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #616161 #eeeeee;
}

*::-moz-scrollbar-track {
  background: #eeeeee;
}

*::-moz-scrollbar-thumb {
  background: #616161;
  border-radius: 3px;
}

@media screen {
  .print-only {
    display: none !important;
  }
  .no-print {
    display: inherit !important;
  }
}
@media print {
  .print-only {
    display: inherit !important;
  }
  .no-print {
    display: none !important;
  }
}

.CalendarDay__selected_span {
  background: rgb(15, 117, 188, 30%);
  color: #fff;
  border: 1px solid rgb(15, 117, 188, 30%);
}

.CalendarDay__selected {
  background: #cccccc;
  color: #fff;
  border-color: #242a42;
  cursor: pointer;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(15, 117, 188, 30%) !important;
  border-color: rgb(15, 117, 188, 30%) !important;
  color: #242a42 !important;
}

.DateInput_input_1 {
  @media only screen and (max-width: 767px) {
    font-size: 14px;
    height: 3.21em !important;
  }
  font-size: 12px;
  font-family: 'Open Sans';
  height: 3.7em !important;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.DateInput_input::selection,
.DateInput_input_1::selection {
  border-color: #0f75bc;
}

.DateInput_fang,
.DateInput_fang_1,
.DateRangePickerInput_arrow_svg,
.DateRangePickerInput_arrow_svg_1,
.DateRangePickerInput_arrow,
.DateRangePickerInput_arrow_1 {
  padding-bottom: 4px;
}

.CalendarMonth strong {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans';
  color: #242a42;
  font-weight: 400;
}

.DayPicker_weekHeader_li,
.DayPicker_weekHeader_li_1 {
  font-family: 'Open Sans';
  color: #909090;
}

small {
  font-size: 14px;
  margin-top: -4px;
}

.CalendarDay__selected_start,
.CalendarDay__selected {
  background: #0f75bc !important;
  color: #fff !important;
  border: 1px solid rgb(15, 117, 188, 30%) !important;
}

.CalendarDay__selected_start {
  border: none !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: #fff !important;
}

.CalendarDay__selected_end {
  border: none !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff !important;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: rgb(15, 117, 188, 30%) !important;
  border: 1px solid rgb(15, 117, 188, 30%) !important;
}

.CalendarDay,
.CalendarDay_1,
.CalendarDay__default,
.CalendarDay__default_2 {
  font-family: 'Open Sans';
}

.CalendarDay__selected_span {
  color: #242a42 !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #0f75bc !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.DateInput_input {
  font-weight: 400 !important;
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    font-size: 16px !important;
  }
}

.DateInput__small,
.DateInput__small_2,
.DateInput {
  width: 150px !important;
}

@media only screen and (min-width: 768px) {
  .DateInput__small,
  .DateInput__small_2,
  .DateInput {
    width: 66px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .DateInput__small,
  .DateInput__small_2,
  .DateInput {
    width: 75px !important;
  }
}

.DateRangePickerInput,
.DateRangePickerInput_1,
.DateRangePickerInput__withBorder,
.DateRangePickerInput__withBorder_2 {
  padding-left: 6px;
  width: 310px;
}

@media only screen and (min-width: 768px) and (max-width: 1023.9px) {
  .DateRangePickerInput,
  .DateRangePickerInput_1,
  .DateRangePickerInput__withBorder,
  .DateRangePickerInput__withBorder_2 {
    width: 140px;
  }
}

@media only screen and (min-width: 1024px) {
  .DateRangePickerInput,
  .DateRangePickerInput_1,
  .DateRangePickerInput__withBorder,
  .DateRangePickerInput__withBorder_2 {
    width: 159px;
  }
}

.DateRangePickerInput,
.DateRangePickerInput_1,
.DateRangePickerInput__withBorder,
.DateRangePickerInput__withBorder_2 {
  border: 1px solid #c4c4c4;
}

.DateInput_input,
.DateInput,
.DateRangePickerInput,
.DateRangePickerInput_1 {
  border-radius: 4px;
}

.DateInput_fangShape,
.DateInput_fangShape_1,
.DateInput_fangStroke,
.DateInput_fangStroke_1 {
  display: none !important;
}

.DayPickerNavigation_button__vertical {
  width: 30px !important;
  height: 20px !important;
}

.DayPickerNavigation__vertical,
.DayPickerNavigation__vertical_2,
.DayPickerNavigation__verticalDefault,
.DayPickerNavigation__verticalDefault_3 {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

.DayPicker_transitionContainer__vertical {
  height: 800px !important;
}

.DayPickerNavigation_svg__vertical {
  height: 19px;
  width: 19px;
}

.DayPicker_transitionContainer {
  height: 440px !important;
}

@media only screen and (min-width: 768px) {
  .DayPicker_transitionContainer {
    height: 283px !important;
  }
}
.DateRangePickerInput_clearDates {
  display: flex;
  align-items: center;
}
.SingleDatePicker.SingleDatePicker_1 {
  min-width: 80%;
}
.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2,
.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__disabled.SingleDatePickerInput__disabled_2.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_3 {
  border-radius: 4px;
  min-width: 223px;
  padding-left: 20px;
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2,
  .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__disabled.SingleDatePickerInput__disabled_2.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_3 {
    border-radius: 4px;
    min-width: 223px;
    padding-left: 10px;
  }
}

.SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2,
.SingleDatePickerInput__disabled.SingleDatePickerInput__disabled_2 {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: space-between;
  height: 42px;
}

.SingleDatePickerInput .DateInput_input::placeholder {
  font-size: 16px;
}

.SingleDatePickerInput .DateInput.DateInput_1 {
  min-width: 120px;
}

.SingleDatePickerInput .DateInput_input {
  font-size: 16px;
}

.SingleDatePickerInput input#fileDate.DateInput_input.DateInput_input_1 {
  height: 40px !important;
}

.SingleDatePickerInput .DateInput_input_1 {
  text-align: left;
}
